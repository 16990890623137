import { useContext, MainContext, useMemo, useParams } from "UIV2";
import { Layout } from "./components";
import { Button } from "UIV2/libraries/useXForm";

const ErrorPages = (props) => {
    const { pageName = "Ups!" } = props || {};
    const { navigate, goTo, VAR } = useContext(MainContext);

    /**
     * Get type via params
     */
    const params = useParams();
    const type = String(props?.typeName || params?.typeName || "Unknown");

    /**
     * get description via variables.SERVER_ERRORS via MainContext
     */
    const description = useMemo(() => VAR.SERVER_ERRORS[type][1] || VAR.SERVER_ERRORS.default[1], [type]);

    /**
     * Return
     */
    return (
        <Layout {...{ pageName, ready4Screen: true }} title={pageName}>
            <div>
                <strong>{type}:</strong> {description}
            </div>
            <br />
            <br />
            <Button onClick={() => navigate(-1)} variant="landing">
                Back
            </Button>
            <Button onClick={() => goTo("/")} variant="landing" priority="primary">
                Home
            </Button>
        </Layout>
    );
};
export default ErrorPages;
