import { useLayoutEffect, useContext, MainContext, useMemo, _ } from "UIV2";
import { XFormItem, rules, Select, DateRange, Input, Button, Spinner } from "UIV2/libraries/useXForm";
import { CustomOptionsForGeos, CustomOptionsForPlatforms } from "./Select/CustomOptions/CustomOptions.js";
import { getAccountManagerName } from "UIV2/libraries/PublisherLayout/AccountManager/getAccountManagerName.js";

/** ---------------------------------------------------
 * Input
 --------------------------------------------------- */
const Company = ({ inputName, ...p }) => <XFormItem xTag={["company", "Company Name", Input]} type="text" {...p} />;
const Code = ({ inputName, ...p }) => (
    <XFormItem
        xTag={["code", "Validation Code", Input]}
        required
        validationRules={[rules.hasToBeFilled]}
        type="text"
        {...p}
    />
);
const ConfirmPassword = ({ inputName, ...p }) => (
    <XFormItem
        xTag={["confirmPassword", "Confirm Password", Input]}
        required
        validationRules={[rules.hasToBeFilled, rules.hasToBeAValidPassword, rules.hasToBeEqualToNewPassword]}
        type="password"
        {...p}
    />
);
const Email = ({ inputName, ...p }) => (
    <XFormItem
        xTag={["email", "Email Address", Input]}
        required
        validationRules={[rules.hasToBeFilled, rules.hasToBeAValidEmail]}
        type="email"
        {...p}
    />
);
const WalletId = ({ inputName, ...p }) => (
    <XFormItem
        xTag={["walletId", "Wallet Id", Input]}
        required
        validationRules={[rules.hasToBeFilled, rules.hasToBeAValidWalletId]}
        {...p}
    />
);
const FirstName = ({ inputName, ...p }) => (
    <XFormItem
        xTag={["firstname", "First Name", Input]}
        validationRules={[rules.hasToBeFilled]}
        required
        type="text"
        {...p}
    />
);
const LastName = ({ inputName, ...p }) => (
    <XFormItem
        xTag={["lastname", "Last Name", Input]}
        validationRules={[rules.hasToBeFilled]}
        required
        type="text"
        {...p}
    />
);
const NewPassword = ({ inputName, ...p }) => (
    <XFormItem
        xTag={["newPassword", "New Password", Input]}
        required
        validationRules={[rules.hasToBeFilled, rules.hasToBeAValidPassword]}
        type="password"
        {...p}
    />
);
const Password = ({ inputName, ...p }) => (
    <XFormItem
        xTag={["password", "Password", Input]}
        required
        validationRules={[rules.hasToBeFilled, rules.hasToBeAValidPassword]}
        type="password"
        {...p}
    />
);
const Phone = ({ inputName, ...p }) => (
    <XFormItem
        xTag={["phone", "Phone Number", Input]}
        required
        validationRules={[rules.hasToBeFilled, rules.hasToBeAValidPhoneNumber]}
        type="tel"
        {...p}
    />
);
const Url = ({ inputName, ...p }) => (
    <XFormItem xTag={["url", "Company Website", Input]} validationRules={[rules.hasToBeAValidUrl]} type="text" {...p} />
);
const Search = ({ inputName, ...p }) => <XFormItem xTag={[inputName || "search", "Search", Input]} {...p} />;
const HiddenPageNumber = (p) => (
    <XFormItem xTag={["page", "Page", Input]} defaultValue={1} style={{ display: "none" }} {...p} />
);

/** ---------------------------------------------------
 * Selects
 --------------------------------------------------- */
const Country = ({ inputName, ...p }) => {
    const { metadata, getMetadata } = useContext(MainContext);
    useLayoutEffect(() => getMetadata("geos"), []);
    const options = useMemo(() => {
        let sortedMetadata = [];
        if (metadata?.geos?.data) sortedMetadata = _.sortBy(metadata?.geos?.data, ["country"]);
        return [{ id: 0, country: "Global", code: "global" }, ...sortedMetadata];
    }, [metadata?.geos?.data]);

    return (
        <XFormItem
            xTag={[inputName || "country", "Country", options ? Select : Spinner]}
            options={options}
            showSearch={true}
            allowClear={true}
            getKey={(i) => i.code}
            getLabel={(i) => i.country}
            mode="multiple"
            CustomOptionComponent={CustomOptionsForGeos}
            optionLabelProp="label"
            additionalDropdownStyle={{ minWidth: "300px" }}
            {...p}
        />
    );
};

const PublisherType = ({ inputName, ...p }) => {

    const { metadata, getMetadata } = useContext(MainContext);
    useLayoutEffect(() => getMetadata("publisherTags"), []);
    const options = useMemo(() => metadata?.publisherTags?.data, [metadata?.publisherTags?.data]);

    return (
        <XFormItem
            xTag={[inputName || "type", "Publisher Type", options ? Select : Spinner]}
            validationRules={[rules.hasToBeFilled]}
            required
            options={metadata?.publisherTags?.data}
            getKey={(i) => i.id}
            getLabel={(i) => i.name}
            showSearch={false}
            allowClear={false}
            additionalDropdownStyle={{ minWidth: "300px" }}
            {...p}
        />
    );
};
const AccManager = ({ inputName, defaultValue, disabled, ...p }) => {
    const { metadata, getMetadata } = useContext(MainContext);
    useLayoutEffect(() => getMetadata("users"), []);
    const options = useMemo(() => metadata?.users?.data, [metadata?.users?.data]);

    return (
        <XFormItem
            xTag={[inputName || "accountManager", "Account Manager", options ? Select : Spinner]}
            options={metadata?.users?.data}
            getKey={(i) => i.id}
            getLabel={(i) => getAccountManagerName(i)}
            showSearch={false}
            allowClear={false}
            defaultValue={defaultValue}
            disabled={disabled}
            additionalDropdownStyle={{ minWidth: "300px" }}
            {...p}
        />
    );
};
const Verticals = ({ inputName, ...p }) => {
    const { metadata, getMetadata } = useContext(MainContext);
    useLayoutEffect(() => getMetadata("verticalTagsForMarket"), []);
    const options = useMemo(() => metadata?.verticalTagsForMarket?.data, [metadata?.verticalTagsForMarket?.data]);
    return (
        <XFormItem
            xTag={[inputName || "verticals", "Verticals", options ? Select : Spinner]}
            options={options}
            showSearch={true}
            allowClear={true}
            sortByKey="name"
            getKey={(i) => i.id}
            getLabel={(i) => i.name}
            mode="multiple"
            additionalDropdownStyle={{ minWidth: "300px" }}
            {...p}
        />
    );
};
const Platforms = ({ inputName, ...p }) => {
    const { metadata, getMetadata } = useContext(MainContext);
    useLayoutEffect(() => getMetadata("platformTags"), []);
    const options = useMemo(() => metadata?.platformTags?.data, [metadata?.platformTags?.data]);

    return (
        <XFormItem
            xTag={[inputName || "platforms", "Platforms", options ? Select : Spinner]}
            options={options}
            optionButtons={[
                {
                    id: "-1",
                    name: "Select All",
                    result: [35, 36, 37, 41, 40, 39],
                },
                {
                    id: "-2",
                    name: "Select All Desktop",
                    result: [41, 40, 39],
                },
                {
                    id: "-3",
                    name: "Select All Mobile",
                    result: [35, 36, 37],
                },
            ]}
            showSearch={true}
            allowClear={true}
            getKey={(i) => i.id}
            getLabel={(i) => i.name}
            mode="multiple"
            CustomOptionComponent={CustomOptionsForPlatforms}
            additionalDropdownStyle={{ minWidth: "300px" }}
            optionLabelProp="label"
            {...p}
        />
    );
};

const SortDirection = ({
    inputName,
    options = [
        { value: "asc", label: "Asc" },
        { value: "desc", label: "Desc" },
    ],
    defaultValue = "asc",
    ...p
}) => {
    return (
        <XFormItem
            xTag={[inputName || "sortDirection", "Sort", Select]}
            options={options}
            showSearch={false}
            allowClear={false}
            getKey={(i) => i.value}
            getLabel={(i) => i.label}
            sortByKey="label"
            defaultValue={defaultValue}
            additionalDropdownStyle={{ minWidth: "100px" }}
            style={{ maxWidth: "150px" }}
            {...p}
        />
    );
};
const SortBy = ({ inputName, options, defaultValue, ...p }) => {
    // pass example
    //  <XFormItem.SortBy
    //    inputName="sortBy"
    //    options={[
    //          { value: "date", label: "Date" },
    //          { value: "epl", label: "Epl Value" },
    //          { value: "name", label: "Name" },
    //      ]}
    //      defaultValue="name"
    //  />;
    if (!options || !defaultValue) return <>SortBy needs options and defaultValue</>;
    return (
        <XFormItem
            xTag={[inputName || "sortBy", "Sort By", Select]}
            options={options}
            showSearch={true}
            allowClear={false}
            getKey={(i) => i.value}
            getLabel={(i) => i.label}
            sortByKey="label"
            defaultValue={defaultValue}
            additionalDropdownStyle={{ minWidth: "100px" }}
            style={{ maxWidth: "150px" }}
            {...p}
        />
    );
};
export const imNameOptions = [
    { value: "WhatsApp", label: "WhatsApp" },
    { value: "skype", label: "Skype" },
    { value: "telegram", label: "Telegram" },
    { value: "slack", label: "Slack" },
    { value: "fbMessenger", label: "FB Messenger" },
    { value: "hangouts", label: "Google Hangout" },
    { value: "teams", label: "Ms Teams" },
    { value: "colorMessage", label: "Color Message" },
    { value: "imo", label: "Imo" },
    { value: "line", label: "Line" },
    { value: "viber", label: "Viber" },
    { value: "other", label: "Other" },
];
const ImName = ({ inputName, disabled, ...p }) => {
    return (
        <XFormItem
            xTag={[inputName || "imName", "Messenger Tool", Select]}
            validationRules={[rules.hasToBeFilled]}
            required
            options={imNameOptions}
            getKey={(i) => i.value}
            getLabel={(i) => i.label}
            showSearch={true}
            allowClear={false}
            additionalDropdownStyle={{ minWidth: "300px" }}
            disabled={disabled}
            {...p}
        />
    );
};
const ImNameOther = ({ inputName, controlValue, ...p }) => {
    // This is related with imName. If imName === "other" then this is required.

    return (
        <XFormItem
            xTag={[inputName || "imNameOther", "Other Tool Name", Input]}
            validationRules={controlValue === "other" ? [rules.hasToBeFilled] : undefined}
            required={controlValue === "other" ? true : false}
            type="text"
            {...p}
        />
    );
};
const ImAccountName = ({ form, inputName, ...p }) => {
    const isWhatsApp = form?.values?.imName === "WhatsApp";

    return (
        <XFormItem
            xTag={[
                inputName || "imAccountName",
                isWhatsApp ? "WhatsApp Number (If different than 'Phone Number' field)" : "Messenger Account Name",
                Input,
            ]}
            validationRules={[rules.hasToBeFilledForWhatsApp]}
            required={!isWhatsApp}
            type="text"
            {...p}
        />
    );
};

export const socialMediaOptions = [
    { value: "facebook", label: "Facebook" },
    { value: "instagram", label: "Instagram" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "pinterest", label: "Pinterest" },
    { value: "reddit", label: "Reddit" },
    { value: "snapchat", label: "Snapchat" },
    { value: "telegram", label: "Telegram" },
    { value: "tiktok", label: "Tiktok" },
    { value: "twitch", label: "Twitch" },
    { value: "x/twitter", label: "X (Former Twitter)" },
    { value: "youtube", label: "YouTube" },
];
const SocialMediaTypes = ({ inputName, ...p }) => {
    return (
        <XFormItem
            xTag={[inputName || "socialMediaType", "Social Media", Select]}
            options={socialMediaOptions}
            validationRules={[rules.hasToBeFilled]}
            showSearch={true}
            allowClear={false}
            getKey={(i) => i.value}
            getLabel={(i) => i.label}
            required
            additionalDropdownStyle={{ minWidth: "300px" }}
            {...p}
        />
    );
};
const SocialMediaTypeOther = ({ inputName, ...p }) => {
    // This is related with imName. If imName === "other" then this is required.

    return (
        <XFormItem
            xTag={[inputName || "socialMediaTypeOther", "Social Media Url", Input]}
            validationRules={[rules.hasToBeFilled, rules.hasToBeAValidUrl]}
            required
            type="text"
            {...p}
        />
    );
};
const SocialMediaUrl = ({ inputName, ...p }) => (
    <XFormItem
        xTag={[inputName || "socialMediaUrl", "Channel Name", Input]}
        validationRules={[rules.hasToBeFilled]}
        required
        type="text"
        {...p}
    />
);

/** ---------------------------------------------------
 * Date
 --------------------------------------------------- */
const Date = ({ inputName, ...p }) => (
    <XFormItem
        xTag={[inputName || "date", "Date Range", DateRange]}
        validationRules={[rules.hasToBeFilled]}
        required
        defaultValue={"last_7_days"}
        allowClear={false}
        {...p}
    />
);

/** ---------------------------------------------------
 * Buttons
 --------------------------------------------------- */
const PrimarySubmitForLanding = ({ form, title, label, ...p }) => {
    return (
        <Button
            loading={form?.isSubmitting}
            variant="landing"
            priority="primary"
            type="submit"
            disabled={!form?.isValid || !form?.isDirty}
            disableInnerLoading
            label={title || label || "Submit"}
            {...p}
        />
    );
};
const PrimarySubmit = ({ form, title, label, ...p }) => {
    return (
        <Button
            loading={form?.isSubmitting}
            priority="primary"
            type="submit"
            disabled={!form?.isValid || !form?.isDirty}
            {...p}
        >
            {title || label || "Submit"}
        </Button>
    );
};
const Reset = ({ title, label }) => {
    return <Button type="reset">{title || label || "Reset"}</Button>;
};
const DefaultButton = ({ title, label, onClick }) => {
    return <Button onClick={onClick}>{title || label || "Default Button"}</Button>;
};

/** ---------------------------------------------------
 * Export All
 --------------------------------------------------- */
export const XField = {
    AccManager,
    Code,
    Company,
    ConfirmPassword,
    Country,
    Date,
    DefaultButton,
    Email,
    FirstName,
    HiddenPageNumber,
    ImAccountName,
    ImName,
    imNameOptions,
    ImNameOther,
    LastName,
    NewPassword,
    Password,
    Phone,
    Platforms,
    PrimarySubmit,
    PrimarySubmitForLanding,
    PublisherType,
    Reset,
    Search,
    SortBy,
    SortDirection,
    Url,
    Verticals,
    WalletId,
    SocialMediaTypes,
    SocialMediaUrl,
    SocialMediaTypeOther,
};
