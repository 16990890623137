import { typeOfAdvanced, permGroups } from "UIV2";
import { LOCAL_STORAGE_NAMES } from "UIV2/base/context/variables/variables.js";

const localStoreName = LOCAL_STORAGE_NAMES.user;
export const getUserDataFromLocalStorage = () => JSON.parse(localStorage.getItem(localStoreName));
export const sendUserDataToLocalStorage = (userData) => localStorage.setItem(localStoreName, JSON.stringify(userData));
export const deleteUserDataFromLocalStorage = () => localStorage.removeItem(localStoreName);

export const isUserAllowedForISO = (permGroupNameOrGroupArray, userPerm) => {
    /**
     * Usage
     * if(isUserAllowed([gue, inv])) // the obj which comes via siteMap
     * if(isUserAllowed("GUEST_OR_INVALIDATED")) // string for permGroups prop name
     * if(isUserAllowed("ALL_USERS")) // string for permGroups prop name
     */
    const type = typeOfAdvanced(permGroupNameOrGroupArray);
    if (type === "string" && permGroupNameOrGroupArray === "ALL_USERS") {
        return true;
    } else if (
        type === "string" &&
        permGroups[permGroupNameOrGroupArray] &&
        permGroups[permGroupNameOrGroupArray].indexOf(userPerm) > -1
    ) {
        return true;
    } else if (type === "array" && permGroupNameOrGroupArray.indexOf(userPerm) > -1) {
        return true;
    }
    return false;
};
const saveUserAndRedirectHimToCallback = (data, states) => {
    const { setUser, setUserPerm, goTo, callbackLocation, location } = states || {};
    let generatedData;

    //Check observer status
    if (data.observer) generatedData = { ...data, typeName: "Observer" };
    else generatedData = data;

    sendUserDataToLocalStorage(generatedData);
    setUser(generatedData);
    setUserPerm(generatedData.typeName || "Guest");
    const callbackPath = callbackLocation?.current?.pathname;
    const callbackUrl =
        callbackPath && callbackPath !== "null"
            ? callbackPath + (callbackLocation?.current?.search ? callbackLocation?.current?.search : "")
            : undefined;
    if (generatedData.typeName === "Invalidate") goTo("/validate-email");
    else if (callbackUrl && !["null", "/logout", "/purge"].includes(callbackUrl)) {
        goTo(callbackUrl);
        callbackLocation.current = undefined;
    } else if (location?.pathname === "/sign-in" || generatedData.typeName === "Observer") {
        goTo();
    }
};
const loginApiCallISO = (signInFormData, callApi) =>
    callApi({
        method: "POST",
        url: "publisher/account/login",
        params: signInFormData,
        token: false,
    });
export const loginISO = async (incoming, states) => {
    const { callApi, addToSnackbar } = states || {};
    const { signInFormData } = incoming || {};
    const { isValid, data, status } = await loginApiCallISO(signInFormData, callApi);
    if (status === 403) {
        console.log("🔑 Invalidated user");
        saveUserAndRedirectHimToCallback({ email: signInFormData.email, type: "invalidate" }, states);
    } else if (isValid) {
        if (!data?.typeId) {
            addToSnackbar({ status: "error", msg: "Account type is missing. Please contact your admin." });
        } else {
            console.log("🔑 " + data.typeName + " after login");
            saveUserAndRedirectHimToCallback(data, states);
        }
    }
};

export const updateUserISO = (data, states) => {
    const { callbackLocation, location } = states || {};
    callbackLocation.current = location;
    saveUserAndRedirectHimToCallback(data, states);
};
const logoutApiCallISO = (userData, callApi) =>
    callApi({
        method: "POST",
        url: "publisher/account/logout",
        params: userData,
    });
export const logoutISO = async (states) => {
    const { setUser, setUserPerm, goTo, callApi } = states || {};
    const localData = getUserDataFromLocalStorage();
    const res = await logoutApiCallISO(localData, callApi);
    if (res.isValid) {
        console.log("🔑 guest after logout");
        deleteUserDataFromLocalStorage();
        setUser();
        setUserPerm("Guest");
        localStorage.clear();
        goTo("logout");
        return;
    } else {
        logoutISO(states);
    }
};
export const checkUserISO = async (states) => {
    const { location, setUserPerm, callApi } = states || {};
    // first-observer login
    if (location?.pathname === "/observer-login") {
        await observerLoginISO(states);
        return;
    }

    // check if there is a local Data
    const localData = getUserDataFromLocalStorage();

    // continue default if there is no localData.
    if (!localData) {
        setUserPerm("Guest");
        console.log("🔑 guest as default");
        return;
    }

    // If user is invalidated
    if (localData.typeName === "Invalidate") {
        setUserPerm("Invalidate");
        console.log("🔑 invalidate User");
        return;
    }

    // Check if the local data is belong to an observer
    // if there is local Data, check if it is valid.
    const { isValid } = await callApi({
        method: "GET",
        url: "publisher/account/check",
        params: localData,
    });
    if (!isValid) {
        deleteUserDataFromLocalStorage();
        setUserPerm("Guest");
        console.log("🔑 guest after invalid token");
        return;
    }

    // There is a user cookie and it is valid.
    console.log("🔑 " + localData.typeName + " after firstCheck");
    saveUserAndRedirectHimToCallback(localData, states);
};
const observerLoginISO = async (states) => {
    const { location, setUserPerm, addToSnackbar, callApi } = states || {};
    localStorage.clear(); // deleteUserDataFromLocalStorage();
    const searchParams = new URLSearchParams(location?.search);
    const incomingAuthToken = searchParams?.get("authToken");
    console.log(incomingAuthToken);
    if (!incomingAuthToken) {
        setUserPerm("guest");
        console.log("🔑 guest after invalid observer");
        addToSnackbar({ status: "error", msg: "Missing information to login as observer." });
        return;
    }
    /**
     * Make api call
     */
    const { isValid, data } = await callApi({
        method: "POST",
        url: "publisher/observer/login",
        token: incomingAuthToken,
    });
    if (isValid) {
        console.log("🔑 observer");
        saveUserAndRedirectHimToCallback(data, states);
    } else {
        setUserPerm("guest");
        console.log("🔑 guest after invalid observer");
    }
};
