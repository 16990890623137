import { lazy } from "UIV2";

// LANDING PAGE LAZY LOADS
const ForgotPassword = lazy(() => import("UIV2/pages/LandingPages/ForgotPassword.js")),
    Logout = lazy(() => import("UIV2/pages/LandingPages/Logout.js")),
    CacheClean = lazy(() => import("UIV2/pages/LandingPages/CacheClean.js")),
    ObserverLogin = lazy(() => import("UIV2/pages/LandingPages/ObserverLogin.js")),
    PrivacyPolicy = lazy(() => import("UIV2/pages/LandingPages/PrivacyPolicy.js")),
    ResetPassword = lazy(() => import("UIV2/pages/LandingPages/ResetPassword.js")),
    SignIn = lazy(() => import("UIV2/pages/LandingPages/SignIn.js")),
    SignUp = lazy(() => import("UIV2/pages/LandingPages/SignUp.js")),
    TermsOfUse = lazy(() => import("UIV2/pages/LandingPages/TermsOfUse.js")),
    ValidateEmail = lazy(() => import("UIV2/pages/LandingPages/ValidateEmail.js")),
    Welcome = lazy(() => import("UIV2/pages/LandingPages/Welcome.js")),
    WalletStats = lazy(() => import("UIV2/pages/LandingPages/WalletStats/WalletStats.js")),
    // PUBLISHERS DASHBOARD LAZY LOAD
    Account = lazy(() => import("UIV2/pages/User/AccountDetails/AccountDetails.js")),
    ChangePassword = lazy(() => import("UIV2/pages/User/ChangePassword/ChangePassword.js")),
    // Home = lazy(() => import("UIV2/pages/Home/Home.js")),
    Market = lazy(() => import("UIV2/pages/Offers/Market/Market.js")),
    Performance = lazy(() => import("UIV2/pages/Reports/Performance/Performance.js")),
    HowToGuide = lazy(() => import("UIV2/pages/HowToGuide/HowToGuide.js")),
    ShareEarn = lazy(() => import("UIV2/pages/ShareEarn/ShareEarn.js"));

/**
 * PERMS
 */
const gue = "Guest",
    inv = "Invalidate",
    // const adm = "admin";
    // const seo = "seo";
    web = "Website",
    inf = "Influencer",
    net = "Network",
    aff = "Affiliate",
    oth = "Other",
    obs = "Observer";

const ALL_USERS = "ALL_USERS",
    GUEST_OR_INVALIDATED = [gue, inv],
    REAL_PUBLISHERS = [web, net, aff, oth, inf], // Excluding invalidate users and Observers
    OBSERVERS = [obs], // limited publishers
    ALL_PUBLISHERS = [...OBSERVERS, ...REAL_PUBLISHERS]; // Excluding invalidate users but including Observers

export const permGroups = {
    ALL_USERS,
    GUEST_OR_INVALIDATED,
    REAL_PUBLISHERS,
    ALL_PUBLISHERS,
    OBSERVERS,
};

/**
 * SITEMAP - [pageName, componentUrl, Root, Perms, subDirectoryForNavigations, additionalProps]
 */

export const siteMap = [
    /**
     * Publisher Pages
     */

    ["Publisher Home", Performance, "/", ALL_PUBLISHERS],
    ["Performance Report", Performance, "/reports/performance", ALL_PUBLISHERS, "report"],
    ["Share & Earn", ShareEarn, "/share-earn", ALL_PUBLISHERS, "shareEarn"],
    ["Regular Offers", Market, "/offers/market/regulars", ALL_PUBLISHERS, "market", { type: "regular" }],
    ["Banners", Market, "/offers/market/banners", ALL_PUBLISHERS, "market", { type: "native" }],
    ["Applied Offers", Market, "/offers/market/applied", ALL_PUBLISHERS, "market", { type: "applied" }],
    ["All Offers", Market, "/offers/market", ALL_PUBLISHERS, "market", { type: "all" }],
    ["Account Details", Account, "/user/account", ALL_PUBLISHERS, "user"],
    ["Change Password", ChangePassword, "/user/password-change", REAL_PUBLISHERS, "user"],
    ["HowTo Guides", HowToGuide, "/how-to", ALL_PUBLISHERS, "howTo"],
    ["Clean Api Cache", CacheClean, "/cache-cleaning", ALL_PUBLISHERS, "user"],

    /**
     * Landing Pages
     */
    ["Logout", Logout, "/logout", ALL_USERS, "user"],
    ["Observer Login", ObserverLogin, "/observer-login", ALL_USERS],
    ["Privacy & Cookie Policies", PrivacyPolicy, "/policies", ALL_USERS],
    ["Terms Of Use", TermsOfUse, "/terms-of-use", ALL_USERS],
    ["Forgotten Password", ForgotPassword, "/forgotten-password", GUEST_OR_INVALIDATED],
    ["Reset Password", ResetPassword, "/reset-password", GUEST_OR_INVALIDATED],
    ["Sign In", SignIn, "/sign-in", GUEST_OR_INVALIDATED],
    ["Sign Up", SignUp, "/sign-up", GUEST_OR_INVALIDATED],
    ["Validate Account", ValidateEmail, "/validate-email", GUEST_OR_INVALIDATED],
    ["Welcome", Welcome, "/welcome", GUEST_OR_INVALIDATED],
    ["Wallet Stats", WalletStats, "/wallet-stats", ALL_USERS],
];
